import React, { ReactNode } from 'react';

type OrderedListProps = {
  children: ReactNode,
  className?: string
}

const OrderedList = ({ 
  children,
  className
}: OrderedListProps) => {
  return <ol className={className}>{children}</ol>;
};

export default OrderedList;
